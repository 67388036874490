/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

function Footer({
  logo,
  text,
  privacyDoc,
  termsDoc,
}) {
  const location = useLocation();
  const path = location.pathname;
  const homepageClass = (path === '/') ? 'fixed-bottom' : '';
  const homepageStyle = (path === '/') ? { paddingBottom: '100px' } : {};

  return (
    <>
      <div id="footer" className="bg-theme-background">
        <div className="d-flex px-5 py-3" style={{ alignItems: 'center' }}>
          <span style={homepageStyle}>
            {(text || '').split('\n').map(t => (
              <>{t}<br /></>
            ))}
          </span>
        </div>
      </div>
      <div
        className={`bg-lightergray border-gray border-top footer-button-container ${homepageClass}`}
        style={{
          // height: '35%',
          boxShadow: '0 -0.175rem 0.25rem rgb(0 0 0 / 12%)',
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          {privacyDoc && (
          <Link to="/privacy" className="text-smokygray font-weight-bold footer-button">
            Privacy Policy
          </Link>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-center footer-button">
          {termsDoc && (
          <Link to="/terms" className="text-smokygray font-weight-bold">
            Terms of Use
          </Link>
          )}
        </div>
        <div className="d-flex ms-md-auto justify-content-center me-4">
          <div
            className="img-contain"
            style={{
              backgroundImage: `url(${logo})`,
              height: 'auto',
              width: '200px',
              marginBottom: '6px',
            }}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  logo: state.theme.images?.poweredByLogo,
  text: state.theme.disclaimerText,
  privacyDoc: state.theme.privacyUrl,
  termsDoc: state.theme.termsUrl,
});

export default connect(mapStateToProps)(Footer);
