import React from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';

function QuestionsBar({ tel, tty, helpurl }) {
  return (
    <div className="container">
      <div className="mb-2">
        <b className="mt-2">
          For any questions regarding your plan&apos;s benefits and costs, please contact
          your insurance carrier.
        </b>
      </div>
      <div className="mb-3">
        {tel && (
        <span className="me-3">
          {`Phone: ${tel}`}
          <br />
        </span>
        )}
        {tty && (
        <span className="me-3">
          {`TTY: ${tty}`}
          <br />
        </span>
        )}
        {helpurl && (
        <span className="me-3">
          {'Website: '}
          <strong>
            <a
              eventLabel={`http://${helpurl.replace(/^http(s)?:\/\//, '')}`}
              href={`http://${helpurl.replace(/^http(s)?:\/\//, '')}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => ReactGA.send({ hitType: 'pageview', path: helpurl.replace(/^http(s)?:\/\//, '') })}
            >
              {helpurl.replace(/^http(s)?:\/\//, '')}
            </a>
          </strong>
        </span>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ plans = [], responses }) => {
  let {
    WebSiteAddress,
    MemberPhoneNumber,
    NonMemberPhoneNumber,
    TTYTDDPhoneNumber,
  } = plans.map[responses.plan];

  // ----- start fallback section for plan contact info

  // get any plans with the same QID, exclude currently selected DR.PlanID
  const otherYearPlans = plans.list
    .filter(({ QID, PlanID }) => (responses.qid === QID) && (responses.plan !== String(PlanID)))
    .sort((a, b) => ((a.PlanYear > b.PlanYear) ? -1 : 1)); // most recent year first in array

  // if plan is missing data, check most recent other year plan for data
  if (!WebSiteAddress || !TTYTDDPhoneNumber) {
    ({ // eslint-disable-line
      WebSiteAddress,
      MemberPhoneNumber,
      NonMemberPhoneNumber,
      TTYTDDPhoneNumber,
    } = (otherYearPlans[0] || {}));
  }

  // ----- end fallback section

  return {
    tel: MemberPhoneNumber || NonMemberPhoneNumber,
    tty: TTYTDDPhoneNumber,
    helpurl: WebSiteAddress,
  };
};

export default connect(mapStateToProps)(QuestionsBar);
