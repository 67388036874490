import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import TableWide from './wide';
import TableTall from './tall';

function BenefitsBar() {
  const [formulary, setFormulary] = useState({});
  const [years, setYears] = useState([]);
  const {
    dosages: {
      list: dosageIds,
    },
    formulary: { list: formularyList },
    plans: { list: plans },
    responses: {
      dosage: dosageId,
      qid,
    },
    theme: {
      avgRetailPrices: avgRetailMap,
      missingYearDisclaimer,
      missingCoverageDisclaimer,
      dosages,
    },
  } = useSelector(store => store);

  const { name: dosageLabel } = dosages.find(d => (String(d.id) === String(dosageId)));

  useEffect(() => {
    // Goal here is to weave together all the drug data into a
    // convenient form for the tables to draw from - do your
    // heavy lifting here instead of spreading it out into all
    // the other components.
    // Map of: dosageId -> plan year -> column name
    const updatedFormulary = {};

    // Only use plan data for current QID.
    const filteredPlans = plans.filter(({ QID }) => qid === QID);

    const updatedYears = filteredPlans.map(({ PlanYear }) => PlanYear);
    setYears(updatedYears);

    dosageIds.forEach(({ id }) => {
      updatedFormulary[id] = {};

      filteredPlans.forEach(({ PlanYear, PlanID, DrugDeductibleLimit }) => {
        const formularyMap = formularyList
          .filter((dosage) => dosage.PlanID === PlanID && dosage.DosageID === Number(id))
          .reduce((thisMap, dosage) => ({ ...thisMap, [dosage.CoveragePhase]: dosage }), {});
        const { IsCMSExcluded } = Object.values(formularyMap)[0] ?? {};

        const beforeAlt = DrugDeductibleLimit > 0 ? 'You pay full price' : '-';
        const gapAlt = IsCMSExcluded ? 'You pay full price' : 'You pay 25%';
        const cataAltNoGap = IsCMSExcluded ? 'You pay full price' : 'You pay 5%';
        const cataAlt = formularyMap[20] ? '-' : cataAltNoGap;

        updatedFormulary[id][PlanYear] = {
          deductible: DrugDeductibleLimit,
          before: formularyMap[0] ?? beforeAlt,
          after: formularyMap[10],
          gap: formularyMap[20] ?? gapAlt,
          cata: formularyMap[30] ?? cataAlt,
          avgRetail: avgRetailMap[PlanYear][id],
        };
      });
    });

    setFormulary(updatedFormulary);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="pt-5">
      <h1 className="text-theme-header">Your plan&apos;s benefits</h1>
      <div>
        {`The plan you selected provides the following coverage for ${dosageLabel}:`}
      </div>
      { missingYearDisclaimer && years.length === 1
        ? (
          <div>
            If only prior or current plan year benefits are displayed below then the plan
            was not available from the Payer in the year not displayed.
          </div>
        )
        : null }

      <TableWide formulary={formulary[dosageId]} years={years} />
      <TableTall formulary={formulary[dosageId]} years={years} />

      {missingCoverageDisclaimer && (
        <div className="mb-4">
          {missingCoverageDisclaimer}
        </div>
      )}
      <div className="mb-5">
        The benefits shown above apply for standard pharmacies that are in the
        plan&apos;s network. If your plan offers a preferred pharmacy network and you choose
        to use a preferred pharmacy, then the amount you will pay may be lower.
        Note that your plan may have additional requirements, such as Prior Authorization.
      </div>
    </Container>
  );
}

export default BenefitsBar;
