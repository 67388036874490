import React from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import Header from './Header';
import DosageBar from './DosageBar';
import BenefitsBar from './benefits-bar';
import CostsBar from './CostsBar';
import QuestionsBar from './QuestionsBar';
import ExtraHelpBar from './ExtraHelpBar';
import InNeedBar from './InNeedBar';

class Benefits extends React.PureComponent {
  componentDidMount() {
    const {
      carrier,
      qid,
      selectedDosage,
      dosages,
      planID,
      plansMap,
    } = this.props;
    const planName = plansMap[planID].PlanName;
    const dosage = dosages.find((d) => Number(d.id) === Number(selectedDosage)).name;
    const dimensions = {
      carrier,
      plan_name: planName || 'Unknown',
      qid,
      dosage,
      org_name: plansMap[planID].OrganizationName,
    };
    ReactGA.event('viewed_benefits', dimensions);
  }

  render() {
    const { showCosts } = this.props;
    return (
      <>
        <Header />
        <DosageBar />
        <BenefitsBar />
        {showCosts && (
          <CostsBar />
        )}
        <QuestionsBar />
        <ExtraHelpBar />
        <InNeedBar />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  showCosts: state.theme.features?.estCosts,
  carrier: state.responses.carrier,
  qid: state.responses.qid,
  planID: Number(state.responses.plan),
  selectedDosage: state.responses.dosage,
  dosages: state.theme.dosages,
  plansMap: state.plans.map,
});

const mapDispatchToProps = {
  setResponse: (name, value) => ({ type: 'SET_RESPONSE', name, value }),
};

export default connect(mapStateToProps, mapDispatchToProps)(Benefits);
