import React from 'react';
import { connect } from 'react-redux';
import Phase from '../BenefitPhase';

function BenefitsRow({
  year,
  showAvgRetail,
  formulary: {
    deductible,
    before,
    after,
    gap,
    cata,
    avgRetail,
  },
}) {
  return (
    <tr className="text-center benefits-row mb-2">
      <th scope="row" className="text-right">{year}</th>
      <td>{`$${deductible}`}</td>
      <Phase dosage={before} />
      <Phase dosage={after} />
      <Phase dosage={gap} />
      <Phase dosage={cata} />
      {showAvgRetail && (
      <td>{`$${avgRetail}`}</td>
      )}
    </tr>
  );
}

const mapStateToProps = (state) => ({
  showAvgRetail: state.theme.avgRetail,
});

export default connect(mapStateToProps)(BenefitsRow);
