import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Input,
  FormFeedback,
  Spinner,
} from 'reactstrap';
import { fipsSearch } from '../../actions/plan-search';
import formularySearch from '../../actions/formulary-search';
import CountyModal from './CountyModal';
import CountyErrorModal from './CountyErrorModal';

function ZipSearch() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [zip, setZip] = useState('');
  const [valid, setValid] = useState(false);
  const [wasValid, setWasValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    responses: { drug },
    theme: { busy },
  } = useSelector(store => store);

  // dispatch functions
  function setResponse(name, value) {
    dispatch({ type: 'SET_RESPONSE', name, value });
  }
  function openModal(id) {
    dispatch({ type: 'OPEN_MODAL', id });
  }
  function loadCounties(list) {
    dispatch({ type: 'LOAD_COUNTIES', list });
  }
  function loadPlans(list) {
    dispatch({ type: 'LOAD_PLANS', list });
  }
  function loadFormulary(list) {
    dispatch({ type: 'LOAD_FORMULARY', list });
  }
  function setBusy(value) {
    dispatch({ type: 'UPDATE_THEME', name: 'busy', value });
  }

  async function handleSearch(event) {
    event.preventDefault();

    if (valid) {
      if (!drug) {
        dispatch({ type: 'UPDATE_THEME', name: 'missingDrugError', value: true });
      } else {
        try {
          setLoading(true);
          setBusy(true);
          const [counties] = await Promise.all([
            fipsSearch(zip),
            setResponse('zip', zip),
          ]);

          if (!counties.length) {
            openModal('countynone');
          } else if (counties.length > 1) {
            await loadCounties(counties);
            openModal('countypick');
          } else {
            const [{ CountyFIPS }] = counties;
            const { Plans, DrugFormularies } = await formularySearch({ county: CountyFIPS });

            await Promise.all([
              loadPlans(Plans),
              loadFormulary(DrugFormularies),
            ]);
            navigate('/carrier');
          }
        } catch (error) {
          openModal('countyerror');
        } finally {
          setLoading(false);
          setBusy(false);
        }
      }
    }
  }

  function handleChange({ target: { value } }) {
    const newValid = /^\d{5}$/.test(value);
    setZip(value);
    setValid(newValid);

    if (valid) {
      setWasValid(true);
    }
  }

  return (
    <div className="h-100 pt-3 d-flex flex-column">
      <CountyModal />
      <CountyErrorModal />

      <form>
        <div className="mb-3">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="zipsearch" className="h4 font-weight-bold form-label">
            Enter your zip code to begin
          </label>
          <div className="row w-75">
            <div className="col">
              <Input
                id="zipsearch"
                style={{ fontSize: '1.5rem' }}
                placeholder="Zip Code..."
                onChange={(e) => handleChange(e)}
                value={zip}
                required
                invalid={wasValid && !valid && zip.length > 0}
                valid={valid}
                disabled={busy}
              />
              <FormFeedback className="text-nowrap position-absolute">
                Please enter a five-digit zip code.
              </FormFeedback>
            </div>
            <div className="col">
              <button
                type="submit"
                className="btn btn-theme-button d-block w-100"
                onClick={(e) => handleSearch(e)}
                style={{ fontSize: '1.5rem' }}
                disabled={!valid || busy}
              >
                {busy && loading && (
                  <Spinner size="sm" className="me-1" />
                )}
                Continue
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ZipSearch;
